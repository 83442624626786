.card-section {
  padding: 1% 0 2% 0;
  // center the cards
  .card-column {
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 0 0 2rem 0;
  }
}
