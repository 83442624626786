.tab-contents {
  // background-color: white;
  min-height: calc(
    100% - 20px
  ); // subtract any fixed heights from the parent element(s)
  min-height: calc(
    60vh - 20px
  ); // if content is too small, set min-height to 60vh
  border-radius: 0.5rem;
  .tab-content {
    padding: 0 5em 5em 5em;
  }
}

.tabs .nav-link.active {
  background-color: inherit;
  // color: #333; // Adjust text color as needed
  border-radius: 0;
  border-color: inherit;
  color: blue;
}

.tabs .nav-link {
  color: black;
}

// mobile version

@media (max-width: 768px) {
  .tab-contents {
    min-height: calc(100% - 20px);
    min-height: calc(60vh - 20px);
    border-radius: 0;
    .tab-content {
      padding: 0 1em 1em 1em;
    }
  }
}
