.contact-me {
  h1 {
    text-align: center;
    font-size: 2.5em;
  }

  .contact-info {
    font-size: 1.2em;
    text-align: center;

    a {
      text-decoration: none;
      font-size: 1.3rem;
      margin-bottom: 0.4em;
      color: black;
      align-items: center;
      display: block;
      .mr-2 {
        margin-right: 0.5em;
      }
      svg {
        width: 1.5em;
        height: 1.5em;
      }
      &:hover {
        color: #007bff;
      }
    }
  }
}
