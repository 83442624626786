.page-content {
  padding: 0 10%;
  .project-content {
    text-align: left;

    .tech-stack {
      text-align: left;
      ul {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: left;

        li {
          margin-right: 1em;
          &:before {
            content: "•";
            margin-right: 0.5em;
          }
        }
      }
    }
  }
}
