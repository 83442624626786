.nav-item {
  margin: 0 1rem;
}

footer {
  background-color: #212529;
  position: relative;
  bottom: 0;
  width: 100%;
  z-index: 10;

  a {
    text-decoration: none;
    font-size: 1.2rem;
    color: #9b9d9e;

    &:hover {
      font-weight: bold;
      color: white;
    }
  }
  // @media (max-height: 80vh) {
  //   position: relative;
  // }
  // if page screen is longer than 80vh, then footer is fixed to bottom
}

// @media (max-height: 95vh) {
//   footer {
//     position: fixed;
//     bottom: 0;
//   }
// }
