.tech-skills {
  ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    // center the list
    justify-content: left;

    li {
      margin-right: 1em;
      &:before {
        content: "•";
        margin-right: 0.5em;
      }
    }
  }
}

.core-qual-content {
  text-align: left;
}
