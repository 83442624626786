.certificate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 1rem 0;
  h4 {
    margin: 0;
    font-size: 1.25em;
  }
  .dates {
    font-size: 0.9em;
    color: #777;
  }
}
