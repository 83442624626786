.card {
  width: 340px;
  .btn {
    margin: 0 10px;
  }
  .card-column {
    padding: 2rem;
  }
}

@media (max-width: 1400px) {
  .card {
    width: 300px;
    .btn {
      margin: 5px 10px;
    }
    .card-column {
      padding: 2rem;
    }
  }
}

@media (max-width: 1200px) {
  .card {
    width: 340px;
    .btn {
      margin: 0 10px;
    }
    .card-column {
      padding: 2rem;
    }
  }
}

@media (max-width: 768px) {
  .card {
    width: 380px;
    .btn {
      margin: 0 10px;
    }
    .card-column {
      padding: 2rem;
    }
  }
}

@media (max-width: 480px) {
  .card {
    width: 300px;
    .btn {
      margin: 0 10px;
    }
    .card-column {
      padding: 2rem;
    }
  }
}
