.landing-title {
  position: relative;
  top: 50%;
}

.header-text {
  .landing-text {
    padding: 0 0 1rem 0;
    line-height: 1.1rem;
  }
}

.btn {
  border-color: black;
  background-color: white;
  color: black;
  position: relative;
}

.header-img {
  width: 400px;
  border-radius: 50%;
  clear: both;
}

// add top margin to the header text on mobile
@media (max-width: 768px) {
  .header-text {
    margin-top: 1.5rem;
  }
}
