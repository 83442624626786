img {
  border-radius: 0;
  width: auto;
}

.carousel {
  width: 75%; // Set the width of the component to be 75% of the parent element
  margin: 0 auto; // Center the component horizontally
}

.carousel-item img {
  width: 100%; // Set the width of the images to be 100% of the component width
}

.carousel-caption {
  // low opacity grayish background
  background-color: rgba(70, 69, 69, 0.8);
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  border-radius: 50%;
  background-color: rgba(70, 69, 69, 0.8);
  box-shadow: 0 0 0 4px rgba(70, 69, 69, 0.8);
}

// change background color of button elements inside .carousel-indicators
.carousel-indicators [data-bs-target] {
  margin-bottom: -10px;
  background-color: rgb(56, 56, 56);
}

@media (max-width: 1380px) {
  .carousel-caption {
    h3 {
      font-size: 1.1rem;
    }
    font-size: 0.9rem;
  }
  .carousel {
    margin: auto 0;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .carousel-caption {
    h3 {
      font-size: 0.8rem;
    }
    font-size: 0.6rem;
  }
  .carousel {
    margin: auto 0;
    width: 100%;
  }
}

@media (max-width: 550px) {
  .carousel-caption {
    // hide caption on small screens
    display: none;
  }
  .carousel {
    margin: auto 0;
    width: 100%;
  }
}
