.projects-list {
  display: flex;
  flex-direction: column;

  h4 {
    margin: 0;
    font-size: 1.3rem;
  }

  p {
    margin: 0;
  }

  .filters {
    display: flex;
    flex-wrap: wrap;

    button {
      margin: 0.5rem;
      font-size: 0.75rem;
    }
  }
  .project-names {
    padding-top: 2rem;
  }
}
