.title {
  // center h1 and a in the header and put them close to each other in the same line
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    margin-left: 1rem;
  }
  svg {
    width: 2em;
    height: 2em;
  }
}
