.extracurricular {
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h4 {
      margin: 0;
      font-size: 1.5em;
    }
    .dates {
      font-size: 0.9em;
      color: #777;
    }
  }
  .club {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 1em;
    // align to left
    text-align: left;
  }
  p {
    text-align: left;
  }
  ul {
    text-align: left;
  }
  a {
    color: #777;
    text-decoration: none;
    &:hover {
      color: #000;
    }
  }
}
