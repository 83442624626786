.work-experience {
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h4 {
      margin: 0;
      font-size: 1.5em;
    }
    .dates {
      font-size: 0.9em;
      color: #777;
    }
  }
  .company {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 1em;
    // align to left
    text-align: left;
  }
  p,
  li {
    text-align: left;
  }

  .tech-stack {
    text-align: left;
    ul {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: left;

      li {
        margin-right: 1em;
        &:before {
          content: "•";
          margin-right: 0.5em;
        }
      }
    }
  }
}
